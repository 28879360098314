import { Modal, notification } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AGENT_LOGIN_ROUTE, LOGIN_ROUTE } from 'routes/path';

interface LogOutModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  userType: 'user' | 'agent';
}

const LogOutModal: FC<LogOutModalProps> = ({ isOpen, handleCancel, userType }) => {
  const navigate = useNavigate();

  const confirmLogout = () => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=${new Date(0).toUTCString()};path=/`;
    }
    const loginRoute = userType === 'agent' ? AGENT_LOGIN_ROUTE : LOGIN_ROUTE;

    navigate(loginRoute);
    notification.success({
      message: 'Success',
      description: 'Logout Successful',
    });
  };

  return (
    <Modal open={isOpen} onCancel={handleCancel} centered>
      <div className="flex flex-col gap-6 text-[15px]">
        <div className="flex flex-col gap-2">
          <h1 className="md:font-[700] font-medium">Confirm Logout</h1>
          <p>Are you sure you want to logout?</p>
        </div>
        <div className="flex justify-end">
          <div className="flex gap-4 items-center">
            <button
              onClick={handleCancel}
              className="flex justify-center text-[#1174ED] font-medium"
            >
              Cancel
            </button>
            <button
              onClick={confirmLogout}
              className="flex justify-center items-center gap-2 px-[10px] md:px-[24px] font-medium md:font-bold h-[40px] rounded-md bg-[#FB382B] text-[#FFFFFF]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogOutModal;
