/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, notification } from 'antd';
import arrowDownIcon from 'assets/Arrow - Down 2.svg';
import leftIcon from 'assets/Arrow - Left b.svg';
import chatIcon from 'assets/Chat.svg';
import xIcon from 'assets/Close Square.svg';
import exchangeIcon from 'assets/exchange-svgrepo-com 1.svg';
import ngnIcon from 'assets/flag-for-nigeria-svgrepo-com 1.svg';
import usaIcon from 'assets/Group 1171275979.svg';
import hkdIcon from 'assets/hong-kong-flag-round-circle-icon.svg';
import infoIcon from 'assets/Info Square.svg';
import numberTwoIcon from 'assets/line 1 - icon + text + line (1).svg';
import numberOneIcon from 'assets/line 1 - icon + text + line.svg';
import cynIcon from 'assets/Mask group.svg';
import tickIcon from 'assets/Tick Square.svg';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  useGetAllWalletMutation,
  useGetConverRateMutation,
  useGetRateMutation,
  useWalletSwapMutation,
} from 'services/wallet';
import { useAppDispatch } from 'store';
import { setConvert } from 'store/wallet';
import { walletState } from 'types/wallet';

// import InitiateRequest from './InitiateRequestModal';

interface ConvertionProps {
  isOpen: boolean;
  handleCancel: () => void;
}

interface AccountBalances {
  [currency: string]: string;
}

interface Wallet {
  id: string;
  currency: string;
  available: string;
}

interface WalletResponse {
  data: Wallet[];
}

interface CountryIcon {
  src: string;
  alt: string;
}

const Convertion: FC<ConvertionProps> = ({ isOpen, handleCancel }) => {
  const [getConvert] = useGetConverRateMutation();
  const [getRate] = useGetRateMutation();
  const [walletSwap] = useWalletSwapMutation();
  const dispatch = useAppDispatch();
  const debouncedGetConvertRef = useRef<((amount: string) => void) | null>(null);
  const [getWalletById] = useGetAllWalletMutation();
  const [accountBalances, setAccountBalances] = useState<AccountBalances>({});
  const [selectedIcon, setSelectedIcon] = useState<CountryIcon>({ src: usaIcon, alt: 'USD Icon' });
  const [selectIcon, setSelectIcon] = useState<CountryIcon>({ src: ngnIcon, alt: 'NGN Icon' });
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [dropOpen, setDropOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRefs = useRef<HTMLDivElement>(null);

  const [fromCurrency, setFromCurrency] = useState<string>('NGN');
  const [toCurrency, setToCurrency] = useState<string>('USD');

  const [sendAmount, setSendAmount] = useState<string>('');
  const [receiveAmount, setReceiveAmount] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  // const [initiateRequestOpen, setInitiateRequestOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    if (sendAmount !== '' && receiveAmount !== '') {
      setShowConfirmation(true);
    } else {
      setShowConfirmation(false);
    }
  }, [sendAmount, receiveAmount]);

  useEffect(() => {
    getRate({})
      .unwrap()
      .then((response: { data: any }) => {
        const data = response.data;
        dispatch(setConvert(data));
      });
  }, [dispatch, getRate]);

  const handleSelectCountry = (icon: CountryIcon, currency: string) => {
    setSelectedIcon(icon);
    setToCurrency(currency);
    setDropdownOpen(false);
  };

  const handleSelect = (icon: CountryIcon, currency: string) => {
    setSelectIcon(icon);
    setFromCurrency(currency);
    setDropOpen(false);
  };

  useEffect(() => {
    debouncedGetConvertRef.current = debounce((amount: string) => {
      getConvert({
        from: fromCurrency,
        to: toCurrency,
        amount: parseFloat(amount),
        type: 'SWAP',
      })
        .unwrap()
        .then((response: { data: walletState }) => {
          setReceiveAmount(response.data.conversionResult);
          dispatch(setConvert(response.data));
        })
        .catch((error: { data: { status: number; errors: string[]; message: string } }) => {
          const errorMessage = error.data?.message || 'Conversion failed';
          notification.error({
            message: 'Error',
            description: errorMessage,
          });
        });
    }, 300);
  }, [fromCurrency, toCurrency, getConvert, dispatch]);

  const debouncedGetConvert = useCallback((amount: string) => {
    if (debouncedGetConvertRef.current) {
      debouncedGetConvertRef.current(amount);
    }
  }, []);

  useEffect(() => {
    if (sendAmount) {
      debouncedGetConvert(sendAmount);
    }
  }, [sendAmount, debouncedGetConvert]);

  const handleExchangeClick = useCallback(() => {
    setIsRotated((prev) => !prev);
    setFromCurrency((prev) => {
      const newReceiveCurrency = toCurrency;
      setToCurrency(prev);
      return newReceiveCurrency;
    });

    setSelectIcon(() => ({
      src: selectedIcon.src,
      alt: selectedIcon.alt,
    }));

    setSelectedIcon(() => ({
      src: selectIcon.src,
      alt: selectIcon.alt,
    }));

    const amountToSend = sendAmount || '';

    getConvert({
      from: toCurrency,
      to: fromCurrency,
      amount: parseFloat(amountToSend),
      type: 'SWAP',
    })
      .unwrap()
      .then((response: { data: walletState }) => {
        setReceiveAmount(response.data.conversionResult);
        dispatch(setConvert(response.data));
      })
      .catch((error: { data: { status: number; errors: string[]; message: string } }) => {
        const errorMessage = error.data?.message || 'Conversion failed';
        notification.error({
          message: 'Error',
          description: errorMessage,
        });
      });
  }, [fromCurrency, toCurrency, selectIcon, selectedIcon, sendAmount, getConvert, dispatch]);

  const handleSendAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setSendAmount(value);
    }
  };

  const handleSendClick = () => {
    if (sendAmount && receiveAmount) {
      const payload = {
        from: fromCurrency,
        to: toCurrency,
        amount: parseFloat(sendAmount),
      };
      walletSwap(payload)
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Convertion Swap successful',
          });
          handleCancel();
          setSendAmount('');
          setReceiveAmount('');
        })
        .catch((error: { data: { message: string } }) => {
          const errorMessage = error.data?.message || 'Swap failed';
          notification.error({
            message: 'Error',
            description: errorMessage,
          });
        });
    }
  };

  useEffect(() => {
    const fetchWalletBalances = async () => {
      try {
        const walletResponse: WalletResponse = await getWalletById({}).unwrap();
        const balances: AccountBalances = {};

        walletResponse.data.forEach((wallet) => {
          balances[wallet.currency] = parseFloat(wallet.available).toFixed(2);
        });

        setAccountBalances(balances);
      } catch (error) {
        // console.error('Failed to fetch wallet balances:', error);
      }
    };

    fetchWalletBalances();
  }, [getWalletById]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
      if (dropdownRefs.current && !dropdownRefs.current.contains(event.target as Node)) {
        setDropOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, dropdownRefs]);

  const availableCurrencies = Object.keys(accountBalances);

  // const handleSupportClick = () => {
  //   handleCancel();
  //   setInitiateRequestOpen(true);
  // };

  const handleSupportClick = () => {
    handleCancel();
    window.open('https://wa.me/+2347019648995?text=Hello%2C%20I%20need%20support', '_blank');
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        className="modal"
        width="55%"
        style={{
          top: 0,
          height: '100vh',
          padding: 0,
        }}
        styles={{ body: { overflowY: 'auto', height: 'calc(100vh - 55px)' } }}
      >
        <div className="flex flex-col gap-3 px-[34px] py-[20px]">
          <div className="flex justify-between text-[#343434] font-[900] text-[24px] leading-[40px]">
            <h1>Convert currencies between accounts</h1>
            <button onClick={handleCancel}>
              <img src={xIcon} alt={xIcon} className="h-[18px] w-[18px]" />
            </button>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-2">
              <div className="relative top-2">
                <img src={numberOneIcon} alt={numberOneIcon} />
              </div>
              <div className="flex flex-col w-[100%]">
                <h1 className="font-[900] text-[20px] leading-[40px] text-[#343434]">
                  Choose Amount
                </h1>
                <div className="flex flex-col gap-4 border-[0.5px] border-[#EAECF0] rounded-[10px] p-[20px]">
                  <div className="flex justify-between">
                    <div ref={dropdownRefs} className="flex flex-col gap-1">
                      <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                        I want to send
                      </p>
                      <div className="input">
                        <img
                          src={selectIcon.src}
                          alt={selectIcon.alt}
                          className="w-[25.2px] h-[25.2px] relative top-[15px] left-6"
                        />
                        <button onClick={() => setDropOpen(!dropOpen)}>
                          <img
                            src={arrowDownIcon}
                            alt={arrowDownIcon}
                            className="w-[11.67px] h-[5.83px] relative z-10 bottom-5 left-[80px]"
                          />
                        </button>
                        <input
                          value={sendAmount}
                          onChange={handleSendAmountChange}
                          placeholder="Enter amount"
                          className="h-[46px] pl-[120px] pr-3 w-[250px] border border-[#D0D0D0] rounded-[12px] relative bottom-[20px] -mb-[20px] right-2 -mr-2"
                        />
                      </div>
                      {dropOpen && (
                        <div className="bg-shadow flex flex-col bg-white absolute mt-[5rem] ml-2 w-[240px] font-[500]">
                          {availableCurrencies.includes('USD') && (
                            <button
                              onClick={() => handleSelect({ src: usaIcon, alt: 'USD Icon' }, 'USD')}
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={usaIcon} alt={usaIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              USD
                            </button>
                          )}
                          {availableCurrencies.includes('NGN') && (
                            <button
                              onClick={() => handleSelect({ src: ngnIcon, alt: 'NGN Icon' }, 'NGN')}
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={ngnIcon} alt={ngnIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              NGN
                            </button>
                          )}
                          {availableCurrencies.includes('CNY') && (
                            <button
                              onClick={() => handleSelect({ src: cynIcon, alt: 'CNY Icon' }, 'CNY')}
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={cynIcon} alt={cynIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              CNY
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="relative top-[40px]">
                      <button onClick={handleExchangeClick}>
                        <img
                          src={exchangeIcon}
                          alt="Exchange Icon"
                          className={`transition-transform duration-500 ease-in-out ${
                            isRotated ? 'rotate-360' : ''
                          }`}
                        />
                      </button>
                    </div>
                    <div ref={dropdownRef} className="flex flex-col gap-1">
                      <p className="text-[#343434] font-[500] text-[16px] leading-[22.4px]">
                        Beneficiary gets
                      </p>
                      <div className="input">
                        <img
                          src={selectedIcon.src}
                          alt={selectedIcon.alt}
                          className="w-[25.2px] h-[25.2px] relative top-[15px] left-6"
                        />
                        <button onClick={() => setDropdownOpen(!dropdownOpen)}>
                          <img
                            src={arrowDownIcon}
                            alt={arrowDownIcon}
                            className="w-[11.67px] h-[5.83px] relative z-10 bottom-5 left-[80px]"
                          />
                        </button>
                        <input
                          value={receiveAmount}
                          readOnly
                          placeholder="Enter amount"
                          className="h-[46px] pl-[120px] pr-3 w-[250px] border border-[#D0D0D0] rounded-[12px] relative bottom-[20px] -mb-[20px] right-2 -mr-2"
                        />
                      </div>
                      {dropdownOpen && (
                        <div className="bg-shadow flex flex-col bg-white absolute mt-[5rem] ml-2 w-[240px] font-[500]">
                          {availableCurrencies.includes('USD') && (
                            <button
                              onClick={() =>
                                handleSelectCountry({ src: usaIcon, alt: 'USD Icon' }, 'USD')
                              }
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={usaIcon} alt={usaIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              USD
                            </button>
                          )}
                          {availableCurrencies.includes('NGN') && (
                            <button
                              onClick={() =>
                                handleSelectCountry({ src: ngnIcon, alt: 'NGN Icon' }, 'NGN')
                              }
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={ngnIcon} alt={ngnIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              NGN
                            </button>
                          )}
                          {availableCurrencies.includes('CNY') && (
                            <button
                              onClick={() =>
                                handleSelectCountry({ src: cynIcon, alt: 'CNY Icon' }, 'CNY')
                              }
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={cynIcon} alt={cynIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              CNY
                            </button>
                          )}
                          {availableCurrencies.includes('HKD') && (
                            <button
                              onClick={() =>
                                handleSelectCountry({ src: hkdIcon, alt: 'HKD Icon' }, 'HKD')
                              }
                              className="flex gap-1 items-center hover:bg-[#f5f5f5] py-[5px] pl-[16px]"
                            >
                              <img src={hkdIcon} alt={hkdIcon} className="w-[25.2px] h-[25.2px]" />{' '}
                              HKD
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-2 pl-[20px] items-center bg-[#F5F5F5] h-[46px] rounded-[10px] w-[250px]">
                    <p className="text-[#666666] font-[500] text-[12px] leading-[16.2px]">
                      Available Balance
                    </p>
                    <h1 className="text-[#343434] font-[900] text-[14px] leading-[40px]">
                      {accountBalances[fromCurrency] || 'not found'}
                    </h1>
                  </div>
                  <div className="flex justify-between items-center">
                    <button
                      onClick={handleSendClick}
                      disabled={!sendAmount}
                      className="flex justify-center items-center rounded-[10px] font-[700] text-[14px] leading-[18.86px] text-[#FFFFFF] bg-[#1174ED] border border-[#1174ED] h-[40px] w-[100px]"
                    >
                      Continue
                    </button>
                    <div className="flex gap-4 text-[#666666]">
                      <div className="flex justify-center gap-1 px-[8px] items-center py-[6px] w-[120px] h-[24.29px] font-[500] bg-[#FFF7F2] rounded-[6.94px] text-[7.5px] leading-[13.12px]">
                        <div>
                          <img src={infoIcon} alt={infoIcon} />
                        </div>
                        <p>
                          FX rates will refresh in{' '}
                          <span className="font-[700] text-[#000]">30s</span>
                        </p>
                      </div>
                      <div className="flex justify-center px-[8px] items-center py-[6px] w-[120px] h-[24.29px] font-[500] bg-[#FFF7F2] rounded-[6.94px] text-[7.5px] leading-[13.12px]">
                        <p>Guaranteed rates 0.10 (1.5345)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showConfirmation && (
              <div className="flex gap-2">
                <div className="relative top-2">
                  <img src={numberTwoIcon} alt={numberTwoIcon} />
                </div>
                <div className="flex flex-col w-[100%]">
                  <h1 className="font-[900] text-[20px] leading-[40px] text-[#343434]">
                    Confirm conversion
                  </h1>
                  <div className="flex bg-[#F0FFF9] border border-[#037847] w-[600px py-[8px] rounded-[10px]">
                    <div className="flex gap-1 items-center p-[8px]">
                      <img src={tickIcon} alt={tickIcon} />
                      <div>
                        <h1 className="text-[#27303A] font-[700] text-[16px] leading-[22.4px]">
                          Confirm Conversion
                        </h1>
                        <p className="font-[500] text-[14px] leading-[18.9px]">
                          You are converting{' '}
                          <span className="font-[700]">
                            {sendAmount} {fromCurrency}
                          </span>{' '}
                          to
                          <span className="font-[700]">
                            {' '}
                            {receiveAmount} {toCurrency}.
                          </span>{' '}
                          Your request would be added to the approval queue.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center mt-[50px]">
            <button
              onClick={handleCancel}
              className="flex justify-center gap-2 items-center text-[#1174ED] text-[16px] leading-[22.4px] font-[700]"
            >
              <img src={leftIcon} alt={leftIcon} />
              Back
            </button>
            <button
              onClick={handleSupportClick}
              className="flex justify-center gap-2 items-center rounded-[10px] bg-[#1174ED] px-[7px] py-[20px] h-[40px] w-[170px] font-[700] text-[14px] text-[#ffff] leading-[18.86px]"
            >
              <img src={chatIcon} alt={chatIcon} />
              Talk to Support
            </button>
          </div>
        </div>
      </Modal>
      {/* <InitiateRequest
        openModal={initiateRequestOpen}
        close={() => setInitiateRequestOpen(false)}
      /> */}
    </div>
  );
};

export default Convertion;
